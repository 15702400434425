import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/printer-details";
import Utility from "../../../shared/utility.js";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      isPrinter: true,
      unitIdentifier: "",
      validatedUnitIdent: true,
      isFormValid: false,
      pass: null,
      searchRecords: "",
      unitIdentifyValid: false,
      btnName: "",
      searchClicked: false,
      mainKey: null,
      shippingResult: [],
      searchIdentifier: "",
      selectedType: "O",
      secondaryName: EncryptUtility.localStorageDecrypt('secondaryName'),
      showPrimary: false,
      reprintShippingTable: false,
      searchdisable: true,
      dynamicLabel: "",
      printDisabled: false,
      selectedLabel: [],
      headersReprintShipping: [
        {
          text: "Direction",
          value: "type",
          class: "primary customwhite--text",
        },
        {
          text: "Order Type",
          value: "ord_type",
          class: "primary customwhite--text",
        },
        { text: "Order", value: "ordnum", class: "primary customwhite--text" },
        {
          text: "Waybill",
          value: "waybill",
          class: "primary customwhite--text",
        },
        { text: "Box#", value: "box_no", class: "primary customwhite--text" },
        {
          text: "Box Qty",
          value: "shipped",
          class: "primary customwhite--text",
        },
      ],
      max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character", (v) => !!v || "Field is required"],
      max5Rule: [(v) => (v || "").length <= 5 || "Max Length of 5 character", (v) => !!v || "Field is required"],
      searchList: [
        {
          text: "ORDER",
          value: "O",
        },
        {
          text: "PACKING SLIP",
          value: "P",
        },
        {
          text: "WAYBILL",
          value: "W",
        },
        {
          text: "BCN",
          value: "B",
        },
        {
          text: "SERIAL#",
          value: "S",
        },
        {
          text: "ESN",
          value: "E",
        },
      ],
      disableSearchBy: true,
      printerConfigured: false,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.searchdisable = true;
    if (EncryptUtility.localStorageDecrypt('secondaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('secondaryLabelSize') !== undefined) {
      if (EncryptUtility.localStorageDecrypt('secondaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('secondaryPrinterType') !== undefined) {
        this.secondaryLabelSize = EncryptUtility.localStorageDecrypt('secondaryLabelSize');
        this.secondaryPrinterType = EncryptUtility.localStorageDecrypt('secondaryPrinterType');
        this.secondaryName = EncryptUtility.localStorageDecrypt('secondaryName');
        this.disableSearchBy = false;
        this.validatedUnitIdent = false;
        this.printerConfigured = false;
        this.selectedType = "O";
        this.onTypeChange();
      }
    } else {
      this.disableSearchBy = true;
      this.printerConfigured = true;
      this.validatedUnitIdent = true;
      this.selectedType = "";
      let Alert = {
        type: "error",
        isShow: true,
        message: "Shipping Printer is not configured, please configure printer.",
      };
      this.$store.commit("ConfigModule/Alert", Alert);
    }
  },
  mounted() {},
  methods: {
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Search By change event
    onTypeChange() {
      this.searchIdentifier = "";
      this.validatedUnitIdent = false;
      const selectedItem = this.searchList.find((item) => item.value === this.selectedType);
      const selectedText = selectedItem ? selectedItem.text : null;
      this.dynamicLabel = "Search By " + selectedText;
      this.$refs.UnitIdentifierForm.resetValidation();
      this.totalRecords = 0;
      this.searchRecords = "";
      this.printDisabled = true;
      this.reprintShippingTable = false;
      this.shippingResult = [];
    },
    //Reset Function
    resetFunction() {
      this.selectedType = "O";
      this.onTypeChange();
      this.searchIdentifier = "";
      this.$refs.UnitIdentifierForm.resetValidation();
      this.isFormValid = false;
      this.reprintShippingTable = false;
      this.searchdisable = true;
      this.printerConfigured = false;
      if (EncryptUtility.localStorageDecrypt('secondaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('secondaryLabelSize') !== undefined) {
        if (EncryptUtility.localStorageDecrypt('secondaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('secondaryPrinterType') !== undefined) {
          this.validatedUnitIdent = false;
          this.disableSearchBy = false;
        }
      } else {
        this.validatedUnitIdent = true;
        this.disableSearchBy = true;
      }
    },
    //Searching the Identifer value
    getActionData() {
      this.shippingResult = [];
      this.selectedLabel = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        id: this.searchIdentifier,
        type: this.selectedType,
        user_id: parseInt(this.userId),
      };
      this.axios
        .post("/sh/shipping_reprint_label", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.shippingResult = responsedata.Result;
            this.totalRecords = this.shippingResult.length;
            this.printDisabled = false;
            this.reprintShippingTable = true;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.shippingResult = [];
          this.totalRecords = 0;
          this.printDisabled = true;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Print button click event
    printShippingLabel() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      if (this.selectedLabel !== undefined && this.selectedLabel.length !== 0) {
        if (EncryptUtility.localStorageDecrypt('secondaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('secondaryLabelSize') !== undefined) {
          this.secondaryLabelSize = EncryptUtility.localStorageDecrypt('secondaryLabelSize');
        }
        if (EncryptUtility.localStorageDecrypt('secondaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('secondaryPrinterType') !== undefined) {
          this.secondaryPrinterType = EncryptUtility.localStorageDecrypt('secondaryPrinterType');
        }
        for (let i = 0; i < this.selectedLabel.length; i++) {
          if (this.selectedLabel[i].type == "OUTBOUND") {
            if (this.selectedLabel[i].api_active) {
              let fedExUpsObj = {
                userId: this.userId,
                ord_key: this.selectedLabel[i].ord_key,
                po_so: this.selectedLabel[i].ord_type == "PO" ? "P" : "S",
                box_key: this.selectedLabel[i].box_id,
                weight: this.selectedLabel[i].weight ? this.selectedLabel[i].weight : 10,
                length: this.selectedLabel[i].length ? this.selectedLabel[i].length : 10,
                width: this.selectedLabel[i].width ? this.selectedLabel[i].width : 10,
                height: this.selectedLabel[i].height ? this.selectedLabel[i].height : 10,
                carrierCode: this.selectedLabel[i].sl_code.includes("UP") ? "UPS" : "FedEx",
                direction: "OUT",
                lbl_size: this.secondaryLabelSize,
                printer_brand: this.secondaryPrinterType,
              };
              this.fedexUpsApiCall(fedExUpsObj);
            } else {
              let genericObj = {
                user_id: parseInt(this.userId),
                ord_type: this.selectedLabel[i].ord_type == "PO" ? "P" : "S",
                ord_key: this.selectedLabel[i].ord_key,
                box_key: this.selectedLabel[i].box_id,
                waybill: this.selectedLabel[i].waybill,
              };
              this.printGenericLabel(genericObj);
            }
          } else if (this.selectedLabel[i].type == "RETURN") {
            if (this.selectedLabel[i].api_active) {
              let fedExUpsObj = {
                userId: this.userId,
                ord_key: this.selectedLabel[i].ord_key,
                po_so: this.selectedLabel[i].ord_type == "PO" ? "P" : "S",
                box_key: this.selectedLabel[i].box_id,
                weight: this.selectedLabel[i].weight ? this.selectedLabel[i].weight : 10,
                length: this.selectedLabel[i].length ? this.selectedLabel[i].length : 10,
                width: this.selectedLabel[i].width ? this.selectedLabel[i].width : 10,
                height: this.selectedLabel[i].height ? this.selectedLabel[i].height : 10,
                carrierCode: this.selectedLabel[i].sl_code.includes("UP") ? "UPS" : "FedEx",
                direction: "IN",
                lbl_size: this.secondaryLabelSize,
                printer_brand: this.secondaryPrinterType,
              };
              this.fedexUpsApiCall(fedExUpsObj);
            } else {
              let genericObj = {
                user_id: parseInt(this.userId),
                ord_type: this.selectedLabel[i].ord_type == "PO" ? "P" : "S",
                ord_key: this.selectedLabel[i].ord_key,
                box_key: this.selectedLabel[i].box_id,
                waybill: this.selectedLabel[i].waybill,
              };
              this.printGenericLabel(genericObj);
            }
          } else if (this.selectedLabel[i].type == "DELIVERY" && this.secondaryPrinterType == "ZEBRA") {
            let deliveryObj = {
              user_id: parseInt(this.userId),
              ord_type: this.selectedLabel[i].ord_type == "PO" ? "P" : "S",
              ord_key: this.selectedLabel[i].ord_key,
              box_key: 0, //Hard coded as per API documentation
              waybill: "", //Hard coded as per API documentation
            };
            this.printLabelDelivery(deliveryObj);
          }
        }
        LoaderDialog.visible = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      } else {
        LoaderDialog.visible = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select any of the value from the data table",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Fedexups API call function
    fedexUpsApiCall(fedexupsObj) {
      this.axios
        .post("sh/shipping_fedex_ups_integration", fedexupsObj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            let labelDetails = responseData?.label;
            if (responseData?.label) {
              PrinterDetails.printLabelData(atob(labelDetails), this.secondaryName);
            }
          } else {
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Print label for generic
    printGenericLabel(genericObj) {
      this.axios
        .post("/sh/shipping_generic_label", genericObj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = response.data.body.message;
            PrinterDetails.printLabelData(responseData, this.secondaryName);
          } else {
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    // Print label for delivery
    printLabelDelivery(deliveryObj) {
      this.axios
        .post("/sh/shipping_reprint_label_delivey", deliveryObj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = response.data.body.message;
            PrinterDetails.printLabelData(responseData, this.secondaryName);
          } else {
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    select(selectedItem) {
      // Update selectedLabel to contain only the selected item
      this.selectedLabel = [selectedItem];
    },
    //Input entered in Unit Identifier
    inputEnter() {
      this.$nextTick(() => {
        if (this.searchIdentifier !== null && this.searchIdentifier !== undefined) {
          if (this.searchIdentifier.length == 0) {
            this.searchdisable = true;
          } else {
            this.searchIdentifier = this.searchIdentifier?.toUpperCase();
            this.searchdisable = false;
          }
        }
      });
    },
    //Searching the Identifier
    searchIdentifer() {
      this.searchdisable = false;
      if (!this.$refs.UnitIdentifierForm.validate()) return false;
      this.pass = 1;
      if (EncryptUtility.localStorageDecrypt('secondaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('secondaryLabelSize') !== undefined) {
        this.secondaryLabelSize = EncryptUtility.localStorageDecrypt('secondaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('secondaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('secondaryPrinterType') !== undefined) {
        this.secondaryPrinterType = EncryptUtility.localStorageDecrypt('secondaryPrinterType');
      }
      this.getActionData();
    },
    //Printer Configured
    printerDetails(labelPrinter) {
      if (!labelPrinter) {
        this.secondaryLabelSize = EncryptUtility.localStorageDecrypt('secondaryLabelSize');
        this.secondaryPrinterType = EncryptUtility.localStorageDecrypt('secondaryPrinterType');
        this.disableSearchBy = false;
        this.printerConfigured = false;
        this.validatedUnitIdent = false;
        this.selectedType = "O";
        this.onTypeChange();
      } else {
        this.disableSearchBy = true;
        this.validatedUnitIdent = true;
        this.printerConfigured = true;
        this.selectedType = "";
      }
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
